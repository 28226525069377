import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Navbar'; // General Navbar for other routes
import DashboardNavbar from './Dashboardnavbar'; // Dashboard-specific Navbar
import Home from './Home';
import Signup from './Signup';
import Signup2 from './Signup2';
import Login from './Login';
import Dashboard from './Dashboard';
import Appointments from './Appointments';
import Pharmacy from './Pharmacy';

const App = () => {
  const location = useLocation();

  // Function to check if the current route is a dashboard, appointments, or pharmacy-related route
  const isSpecialRoute = () => 
    location.pathname.startsWith('/dashboard') || 
    location.pathname.startsWith('/appointments') || 
    location.pathname.startsWith('/pharmacy');

  return (
    <div>
      {/* Render the appropriate Navbar based on the current route */}
      {isSpecialRoute() ? <DashboardNavbar /> : <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup2" element={<Signup2 />} />
        <Route path="/dashboard/*" element={<Dashboard />} /> {/* Added the wildcard for nested routes */}
        <Route path="/appointments/*" element={<Appointments />} /> {/* Added the wildcard for nested routes */}
        <Route path="/pharmacy/*" element={<Pharmacy />} /> {/* Added the wildcard for nested routes */}
        {/* Additional routes can be added here */}
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
