import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell
} from 'recharts';
import { FaUserPlus, FaUserCheck, FaClock, FaCalendarAlt, FaPills, FaProcedures } from 'react-icons/fa';

const Pharmacy = () => {
  const pharmacyData = [
    { category: 'Painkillers', inStock: 120, soldToday: 20, lowStock: 5, totalDoctors: 15, nursesOnDuty: 25 },
    { category: 'Antibiotics', inStock: 200, soldToday: 35, lowStock: 8, totalDoctors: 18, nursesOnDuty: 30 },
    { category: 'Supplements', inStock: 150, soldToday: 25, lowStock: 10, totalDoctors: 12, nursesOnDuty: 20 },
    { category: 'Vaccines', inStock: 90, soldToday: 15, lowStock: 3, totalDoctors: 10, nursesOnDuty: 18 },
    { category: 'Antivirals', inStock: 110, soldToday: 18, lowStock: 7, totalDoctors: 20, nursesOnDuty: 22 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A569BD'];

  const cardData = [
    { title: 'Total Medicines in Stock', value: pharmacyData.reduce((acc, data) => acc + data.inStock, 0), icon: <FaPills /> },
    { title: 'Total Sold Today', value: pharmacyData.reduce((acc, data) => acc + data.soldToday, 0), icon: <FaProcedures /> },
    { title: 'Low Stock Items', value: pharmacyData.reduce((acc, data) => acc + (data.lowStock <= 10 ? 1 : 0), 0), icon: <FaClock /> },
    { title: 'Staff on Duty', value: pharmacyData.reduce((acc, data) => acc + data.nursesOnDuty + data.totalDoctors, 0), icon: <FaUserCheck /> },
  ];

  return (
    <div style={styles.dashboardContainer}>
      <br />
      <div style={styles.tableContainer}>
        <div style={styles.responsiveTableWrapper}>
          <h1 style={styles.header}>Medicines Inventory</h1>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Category</th>
                <th style={styles.tableHeader}>In Stock</th>
                <th style={styles.tableHeader}>Sold Today</th>
                <th style={styles.tableHeader}>Low Stock Alert</th>
                <th style={styles.tableHeader}>Total Doctors</th>
                <th style={styles.tableHeader}>Nurses on Duty</th>
              </tr>
            </thead>
            <tbody>
              {pharmacyData.map((data, index) => (
                <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                  <td style={styles.tableCell}>{data.category}</td>
                  <td style={styles.tableCell}>{data.inStock}</td>
                  <td style={styles.tableCell}>{data.soldToday}</td>
                  <td style={styles.tableCell}>{data.lowStock}</td>
                  <td style={styles.tableCell}>{data.totalDoctors}</td>
                  <td style={styles.tableCell}>{data.nursesOnDuty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={styles.cardsContainer}>
        {cardData.map((card, index) => (
          <div key={index} style={styles.card}>
            <div style={styles.cardIcon}>{card.icon}</div>
            <h3 style={styles.cardTitle}>{card.title}</h3>
            <p style={styles.cardValue}>{card.value}</p>
          </div>
        ))}
      </div>

      {/* Charts for inventory statistics */}
      <div style={styles.chartContainer}>
        <BarChart width={250} height={250} data={pharmacyData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="inStock" fill="#82ca9d" />
          <Bar dataKey="soldToday" fill="#8884d8" />
          <Bar dataKey="lowStock" fill="#ffc658" />
        </BarChart>

        <LineChart width={250} height={250} data={pharmacyData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="inStock" stroke="#8884d8" />
          <Line type="monotone" dataKey="soldToday" stroke="#82ca9d" />
        </LineChart>

        <PieChart width={250} height={250}>
          <Pie
            data={pharmacyData}
            dataKey="soldToday"
            nameKey="category"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {pharmacyData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      <Outlet />
    </div>
  );
};

const styles = {
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f2f5',
    height: '100%',
    overflowX: 'hidden',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: '#ffffff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    flex: '1',
    margin: '0 10px',
    minWidth: '200px',
  },
  cardIcon: {
    fontSize: '2rem',
    color: '#007bff',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#333',
  },
  cardValue: {
    fontSize: '1.5rem',
    color: '#007bff',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  tableContainer: {
    width: '85%',
    margin: '20px 0',
  },
  responsiveTableWrapper: {
    overflowX: 'auto', // Allows horizontal scrolling on smaller screens
    WebkitOverflowScrolling: 'touch',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'center',
    fontSize: '0.9rem',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  tableCell: {
    padding: '12px',
    border: '1px solid #ddd',
  },
  tableHeader: {
    padding: '12px',
    border: '1px solid #ddd',
    backgroundColor: '#007bff',
    color: '#fff',
    fontWeight: 'bold',
  },
  evenRow: {
    backgroundColor: '#f8f9fa',
  },
  oddRow: {
    backgroundColor: '#ffffff',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    marginTop: '20px',
    flexWrap: 'wrap',
  },
};

export default Pharmacy;
