import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setError("Please fill in both fields.");
            return;
        }

        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await axios.post('http://localhost:8000/api/login/', { username, password }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                const { redirect, token } = response.data;
                localStorage.setItem('token', token);

                switch (redirect) {
                    case 'client_dashboard':
                        window.location.href = '/client_dashboard';
                        break;
                    case 'delivery_dashboard':
                        window.location.href = '/delivery_dashboard';
                        break;
                    case 'admin_dashboard':
                        window.location.href = '/admin_dashboard';
                        break;
                    default:
                        setError('Unexpected user type.');
                        break;
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error || 'An error occurred.');
            } else if (error.request) {
                setError('No response received from server.');
            } else {
                setError('Error in setting up the request.');
            }
        }
    };

    return (
        <div style={styles.page}>
            <div style={styles.container}>
                <div style={styles.imageContainer}>
                    <img
                        src="https://img.freepik.com/free-vector/login-concept-illustration_114360-739.jpg"
                        alt="Login Illustration"
                        style={styles.image}
                    />
                </div>
                <div style={styles.formContainer}>
                    <h2 style={styles.heading}>Login</h2>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Username:</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={styles.input}
                                required
                            />
                        </div>
                        <button type="submit" style={styles.button}>Login</button>
                    </form>
                    {error && <p style={styles.error}>{error}</p>}
                    <div style={styles.socialMediaContainer}>
                        <p style={styles.socialText}>Or login with:</p>
                        <div style={styles.iconContainer}>
                            <button style={styles.iconButton}>
                                <img src="https://img.icons8.com/ios-filled/50/000000/google-logo.png" alt="Google" style={styles.icon} />
                            </button>
                            <button style={styles.iconButton}>
                                <img src="https://img.icons8.com/ios-filled/50/000000/facebook-new.png" alt="Facebook" style={styles.icon} />
                            </button>
                            <button style={styles.iconButton}>
                                <img src="https://img.icons8.com/ios-filled/50/000000/twitter-circled.png" alt="Twitter" style={styles.icon} />
                            </button>
                        </div>
                    </div>
                    <p style={styles.signupText}>
                        Don't have an account? <a href="/signup" style={styles.signupLink}>Sign Up</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

const styles = {
    page: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: 'url(https://img.freepik.com/premium-photo/solid-light-blue-background_728418-18496.jpg)',
       // backgroundColor: '#f8f9fa',
        padding: '1rem',
    },
    container: {
        display: 'flex',
        maxWidth: '900px',
        width: '100%',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '12px',
        overflow: 'hidden',
        backgroundColor: '#fff',
    },
    imageContainer: {
        flex: 1,
        backgroundColor: '#007bff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    formContainer: {
        flex: 1,
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    heading: {
        fontSize: '2rem',
        marginBottom: '1.5rem',
        color: '#333',
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '1rem',
    },
    label: {
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '0.5rem',
    },
    input: {
        padding: '0.75rem',
        fontSize: '1rem',
        borderRadius: '6px',
        border: '1px solid #ddd',
        width: '100%',
    },
    button: {
        padding: '0.75rem',
        fontSize: '1rem',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '1rem',
    },
    error: {
        color: 'red',
        marginTop: '1rem',
        textAlign: 'center',
    },
    socialMediaContainer: {
        marginTop: '2rem',
        textAlign: 'center',
    },
    socialText: {
        marginBottom: '1rem',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
    },
    iconButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    icon: {
        width: '40px',
        height: '40px',
    },
    signupText: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    signupLink: {
        color: '#007bff',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
};

export default Login;
