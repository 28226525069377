import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { FaUserInjured, FaCalendarAlt, FaUserMd, FaBell } from 'react-icons/fa';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const Dashboard = () => {
  const [doctors, setDoctors] = useState([]);
  const [staff, setStaff] = useState([]); // New state for staff data
  const [pendingTasks, setPendingTasks] = useState([
    { task: 'Complete medical reports', date: '2024-11-08', status: 'Pending' },
    { task: 'Schedule patient follow-up', date: '2024-11-10', status: 'Pending' },
    { task: 'Update patient records', date: '2024-11-12', status: 'Pending' },
  ]);

  // Sample data for the pie chart
  const patientData = [
    { name: 'Admitted', value: 120 },
    { name: 'Discharged', value: 80 },
    { name: 'Under Observation', value: 40 },
  ];

  // Sample data for yearly income and expenses
  const financeData = [
    { month: 'Jan', income: 12000, expense: 8000 },
    { month: 'Feb', income: 15000, expense: 9000 },
    { month: 'Mar', income: 18000, expense: 7000 },
    { month: 'Apr', income: 20000, expense: 11000 },
    { month: 'May', income: 22000, expense: 12000 },
    { month: 'Jun', income: 25000, expense: 13000 },
    { month: 'Jul', income: 27000, expense: 14000 },
    { month: 'Aug', income: 30000, expense: 15000 },
    { month: 'Sep', income: 32000, expense: 16000 },
    { month: 'Oct', income: 34000, expense: 17000 },
    { month: 'Nov', income: 35000, expense: 18000 },
    { month: 'Dec', income: 40000, expense: 19000 },
  ];

  const COLORS = ['#4CAF50', '#FF9800', '#f44336'];

  const fetchStaff = async () => {
    const data = [
      { name: 'Nurse Sarah', role: 'Nurse', available: true },
      { name: 'John Doe', role: 'Support Staff', available: false },
      { name: 'Emily Rose', role: 'Receptionist', available: true },
    ];
    setStaff(data);
  };


  // Fetch doctors data
  useEffect(() => {
    const fetchDoctors = async () => {
      const data = [
        { name: 'Dr. John Doe', specialization: 'Cardiologist', available: true },
        { name: 'Dr. Jane Smith', specialization: 'Dermatologist', available: false },
        { name: 'Dr. William Johnson', specialization: 'Pediatrician', available: true },
        { name: 'Dr. Anoop', specialization: 'Pediatrician', available: true },
       
      ];
      setDoctors(data);
    };
    fetchDoctors();
  }, []);

  return (
    <div style={styles.dashboardContainer}>
      <br/>
      <br/>

      <h1 style={styles.header}>Hospital Management Dashboard</h1>

      <div style={styles.statsSection}>
  {/* Patient Statistics Card */}
  <div style={{ ...styles.statBox, ...styles.patientStats, backgroundColor: '#F5A623' }}>
    <FaUserInjured style={styles.icon} />
    <h2>Patient Statistics</h2>
    <p>Admitted: <span style={styles.statNumber}>120</span></p>
    <p>Discharged: <span style={styles.statNumber}>80</span></p>
    <p>Under Observation: <span style={styles.statNumber}>40</span></p>
  </div>

  {/* Appointments Overview Card */}
  <div style={{ ...styles.statBox, ...styles.appointmentsOverview, backgroundColor: '#4A90E2' }}>
    <FaCalendarAlt style={styles.icon} />
    <h2>Appointments Overview</h2>
    <p>Today: <span style={styles.statNumber}>25</span></p>
    <p>Upcoming: <span style={styles.statNumber}>60</span></p>
    <p>Canceled: <span style={styles.statNumber}>5</span></p>
  </div>

  {/* Staff Information Card */}
  <div style={{ ...styles.statBox, ...styles.staffInfo, backgroundColor: '#50E3C2' }}>
    <FaUserMd style={styles.icon} />
    <h2>Staff Information</h2>
    <p>Doctors: <span style={styles.statNumber}>45</span></p>
    <p>Nurses: <span style={styles.statNumber}>85</span></p>
    <p>Support Staff: <span style={styles.statNumber}>30</span></p>
  </div>

  {/* Notifications Card */}
  <div style={{ ...styles.statBox, ...styles.notifications, backgroundColor: '#D0021B' }}>
    <FaBell style={styles.icon} />
    <h2>Notifications</h2>
    <p>New messages: <span style={styles.statNumber}>3</span></p>
    <p>Pending tasks: <span style={styles.statNumber}>8</span></p>
    <p>Alerts: <span style={styles.statNumber}>2</span></p>
  </div>
</div>

      {/* Row for Charts and Table */}
      <div style={styles.chartRow}>
      

        {/* Pie Chart for Patient Statistics */}
        <div style={styles.chartContainer}>
          <h2 style={styles.chartTitle}>Patient Statistics Overview</h2>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Tooltip />
              <Legend />
              <Pie
                data={patientData}
                innerRadius={60}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {patientData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
          {/* Bar Chart for Yearly Income and Expenses */}
          <div style={styles.chartContainer}>
          <h2 style={styles.chartTitle}>Yearly Income & Expenses</h2>
          <br/>
          <br/>
          <br/>
          <br/>
       
          <ResponsiveContainer width="80%" height={300}>
            <BarChart data={financeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="income" fill="#4CAF50" name="Income" />
              <Bar dataKey="expense" fill="#f44336" name="Expense" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div style={styles.doctorListContainer}>
        <h2 style={styles.doctorListTitle}>Doctors List</h2>
        <div style={styles.doctorGrid}>
          {doctors.map((doctor, index) => (
            <div key={index} style={styles.doctorCard}>
              <img
                src="https://hips.hearstapps.com/hmg-prod/images/portrait-of-a-happy-young-doctor-in-his-clinic-royalty-free-image-1661432441.jpg"
                alt={doctor.name}
                style={styles.doctorImageSquare}
              />
              <div style={styles.doctorInfo}>
                <strong>{doctor.name}</strong>
                <p>{doctor.specialization}</p>
                <p>{doctor.available ? 'Available' : 'Not Available'}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={styles.staffInfoContainer}>
  {/* Staff Information Table */}
  <h2 style={styles.tableTitle}>Staff Information</h2>
  <table style={styles.table}>
    <thead style={styles.tableHead}>
      <tr>
        <th style={styles.tableCell}>Name</th>
        <th style={styles.tableCell}>Role</th>
        <th style={styles.tableCell}>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={styles.tableCell}>Anoop</td>
        <td style={styles.tableCell}>Nurse</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
      <tr>
        <td style={styles.tableCell}>Manu</td>
        <td style={styles.tableCell}>Accountant</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
      <tr>
        <td style={styles.tableCell}>Arjun</td>
        <td style={styles.tableCell}>Lab Assistant</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
      <tr>
        <td style={styles.tableCell}>Madhav</td>
        <td style={styles.tableCell}>Technitian</td>
        <td style={styles.tableCell}>Active</td>
      </tr>
    </tbody>
  </table>
</div>

{/* Pending Task Details Table */}
<div style={styles.staffInfoContainer}>
  <h2 style={styles.tableTitle}>Pending Task Details</h2>
  <table style={styles.table}>
    <thead style={styles.tableHead}>
      <tr>
        <th style={styles.tableCell}>Task</th>
        <th style={styles.tableCell}>Date</th>
        <th style={styles.tableCell}>Status</th>
      </tr>
    </thead>
    <tbody>
      {pendingTasks.map((task, index) => (
        <tr key={index}>
          <td style={styles.tableCell}>{task.task}</td>
          <td style={styles.tableCell}>{task.date}</td>
          <td style={{ ...styles.tableCell, color: 'red' }}>{task.status}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>


      {/* Nested routes will be rendered here */}
      <Outlet />
    </div>
  );
};

const styles = {
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f2f5',
    minHeight: '120vh',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  statsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    width: '100%',
    justifyContent: 'center',
  },
  statBox: {
    width: '210px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
  },
  icon: {
    fontSize: '2.5rem',
    color: '#777',
    marginBottom: '10px',
  },
  statNumber: {
    fontWeight: 'bold',
    color: '#333',
  },
  chartRow: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: '20px',
    width: '100%',
    marginTop: '40px',
    flexWrap: 'wrap',
  },
  chartContainer: {
    flex: 1,
    maxWidth: '45%',
    minWidth: '300px',
    marginBottom: '20px',
  },
  chartTitle: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  doctorListContainer: {
    marginTop: '40px',
    width: '100%',
  },
  doctorListTitle: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  doctorGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center',
  },
  doctorCard: {
    width: '220px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    padding: '10px',
  },
  doctorImageSquare: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
    marginBottom: '10px',
  },
  doctorInfo: {
    paddingTop: '10px',
    textAlign: 'left',
  },
  staffInfoContainer: {
    width: '80%',
    marginTop: '40px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  tableTitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '15px',
    color: '#333',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '10px',
    textAlign: 'center',
  },
};

export default Dashboard;
