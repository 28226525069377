import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
    setDropdownVisible(false); // Close dropdown if mobile menu is toggled
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    if (window.innerWidth > 768) {
      setMobileMenuVisible(false);
      setDropdownVisible(false); // Close dropdown on desktop view
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-toggle") && dropdownVisible) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible]);

  const closeMenuAndDropdown = () => {
    setMobileMenuVisible(false);
    setDropdownVisible(false);
  };

  const styles = {
    navbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      backgroundColor: '#fff',
      color: '#000',
      position: 'relative',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    logo: {
      height: '50px',
      borderRadius: '50%',
    },
    title: {
      margin: '0 1rem',
      fontSize: '1.5rem',
      color: '#333',
    },
    navLinks: {
      listStyleType: 'none',
      display: isMobile ? (mobileMenuVisible ? 'block' : 'none') : 'flex',
      alignItems: 'center',
      position: isMobile ? 'absolute' : 'static',
      top: isMobile ? '90px' : 'auto',
      left: '0',
      width: '100%',
      backgroundColor: isMobile ? '#fff' : 'transparent',
      padding: '0',
      margin: '0',
      textAlign: isMobile ? 'center' : 'left',
      zIndex: 1,
    },
    navItem: {
      margin: isMobile ? '1rem 0' : '0 1rem',
      position: 'relative',
    },
    link: {
      color: '#000',
      textDecoration: 'none',
      padding: '0.5rem 1rem',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    activeLink: {
      color: '#007bff',
      fontWeight: 'bold',
      borderBottom: '2px solid #007bff',
    },
    linkIcon: {
      marginRight: '0.5rem',
    },
    dropdown: {
      position: 'absolute',
      top: '100%',
      left: '0',
      backgroundColor: '#fff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      listStyleType: 'none',
      padding: '0',
      margin: '0',
      width: '200px',
      borderRadius: '4px',
    },
    dropdownItem: {
      padding: '0.5rem 1rem',
      backgroundColor: '#fff',
      color: '#000',
      borderBottom: '1px solid #ddd',
      textAlign: 'left',
    },
    menuButton: {
      display: isMobile ? 'block' : 'none',
      backgroundColor: 'transparent',
      border: 'none',
      color: '#000',
      fontSize: '1.5rem',
      cursor: 'pointer',
    },
  };

  const navItems = [
    { path: '/', label: 'Home', icon: 'fas fa-home' },
    { path: '/signup', label: 'Signup', icon: 'fas fa-user-plus', isDropdown: true },
    { path: '/dashboard', label: 'Dashboard', icon: 'fas fa-tachometer-alt' },
    { path: '/login', label: 'Login', icon: 'fas fa-sign-in-alt' },
    { path: '/settings', label: 'Settings', icon: 'fas fa-cog' },
  ];

  return (
    <nav style={styles.navbar}>
      <img
        src="https://cdn-icons-png.flaticon.com/512/9193/9193824.png"
        alt="Warehouse Management Logo"
        style={styles.logo}
      />
      <h1 style={styles.title}>HOSPITAL MANAGEMENT</h1>
      <button style={styles.menuButton} onClick={toggleMobileMenu}>
        <i className="fas fa-bars"></i>
      </button>
      <ul style={styles.navLinks}>
        {navItems.map((item) =>
          item.isDropdown ? (
            <li
              key={item.path}
              style={styles.navItem}
              className="dropdown-toggle"
              onClick={toggleDropdown}
            >
              <span
                style={{
                  ...styles.link,
                  ...(location.pathname === item.path ? styles.activeLink : {}),
                }}
              >
                <i className={`${item.icon}`} style={styles.linkIcon}></i>
                {item.label}
              </span>
              {dropdownVisible && (
                <ul style={{ ...styles.dropdown, top: isMobile ? '40px' : '100%' }}>
                  <li style={styles.dropdownItem}>
                    <Link style={styles.link} to="/signup" onClick={closeMenuAndDropdown}>
                      Signup
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          ) : (
            <li key={item.path} style={styles.navItem}>
              <Link
                style={{
                  ...styles.link,
                  ...(location.pathname === item.path ? styles.activeLink : {}),
                }}
                to={item.path}
                onClick={closeMenuAndDropdown}
              >
                <i className={`${item.icon}`} style={styles.linkIcon}></i>
                {item.label}
              </Link>
            </li>
          )
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
