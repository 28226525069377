import React from 'react';
import { Outlet } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import { FaUserPlus, FaUserCheck, FaClock, FaCalendarAlt } from 'react-icons/fa';

const Appointments = () => {
  const appointmentData = [
    { category: 'Statistics', admitted: 120, discharged: 80, underObservation: 40, todaysAppointments: 25, upcomingAppointments: 60, canceledAppointments: 5, doctors: 45, nurses: 85, supportStaff: 30 },
    { category: 'Cardiology', admitted: 50, discharged: 30, underObservation: 15, todaysAppointments: 10, upcomingAppointments: 25, canceledAppointments: 2, doctors: 12, nurses: 20, supportStaff: 5 },
    { category: 'Neurology', admitted: 30, discharged: 20, underObservation: 5, todaysAppointments: 5, upcomingAppointments: 15, canceledAppointments: 1, doctors: 8, nurses: 15, supportStaff: 3 },
    { category: 'Pediatrics', admitted: 70, discharged: 50, underObservation: 20, todaysAppointments: 15, upcomingAppointments: 30, canceledAppointments: 3, doctors: 10, nurses: 25, supportStaff: 7 },
    { category: 'Orthopedics', admitted: 40, discharged: 25, underObservation: 10, todaysAppointments: 8, upcomingAppointments: 20, canceledAppointments: 4, doctors: 5, nurses: 15, supportStaff: 4 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A569BD'];
  
  const cardData = [
    { title: 'Total Admitted', value: appointmentData.reduce((acc, data) => acc + data.admitted, 0), icon: <FaUserPlus /> },
    { title: 'Total Discharged', value: appointmentData.reduce((acc, data) => acc + data.discharged, 0), icon: <FaUserCheck /> },
    { title: 'Total Appointments Today', value: appointmentData.reduce((acc, data) => acc + data.todaysAppointments, 0), icon: <FaClock /> },
    { title: 'Total Upcoming Appointments', value: appointmentData.reduce((acc, data) => acc + data.upcomingAppointments, 0), icon: <FaCalendarAlt /> },
  ];

  return (
    <div style={styles.dashboardContainer}>
      <br />
     <div style={styles.tableContainer}>
  <h1 style={styles.header}>Appointments</h1>
  <div style={styles.responsiveTableWrapper}>
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.tableHeader}>Category</th>
          <th style={styles.tableHeader}>Admitted</th>
          <th style={styles.tableHeader}>Discharged</th>
          <th style={styles.tableHeader}>Under Observation</th>
          <th style={styles.tableHeader}>Today’s Appointments</th>
          <th style={styles.tableHeader}>Upcoming Appointments</th>
          <th style={styles.tableHeader}>Canceled Appointments</th>
          <th style={styles.tableHeader}>Doctors</th>
          <th style={styles.tableHeader}>Nurses</th>
          <th style={styles.tableHeader}>Support Staff</th>
        </tr>
      </thead>
      <tbody>
        {appointmentData.map((data, index) => (
          <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
            <td style={styles.tableCell}>{data.category}</td>
            <td style={styles.tableCell}>{data.admitted}</td>
            <td style={styles.tableCell}>{data.discharged}</td>
            <td style={styles.tableCell}>{data.underObservation}</td>
            <td style={styles.tableCell}>{data.todaysAppointments}</td>
            <td style={styles.tableCell}>{data.upcomingAppointments}</td>
            <td style={styles.tableCell}>{data.canceledAppointments}</td>
            <td style={styles.tableCell}>{data.doctors}</td>
            <td style={styles.tableCell}>{data.nurses}</td>
            <td style={styles.tableCell}>{data.supportStaff}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

      <div style={styles.cardsContainer}>
        {cardData.map((card, index) => (
          <div key={index} style={styles.card}>
            <div style={styles.cardIcon}>{card.icon}</div>
            <h3 style={styles.cardTitle}>{card.title}</h3>
            <p style={styles.cardValue}>{card.value}</p>
          </div>
        ))}
      </div>

      {/* Charts */}
      <div style={styles.chartContainer}>
        {/* Bar Chart */}
        <BarChart width={300} height={300} data={appointmentData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="admitted" fill="#82ca9d" />
          <Bar dataKey="discharged" fill="#8884d8" />
          <Bar dataKey="underObservation" fill="#ffc658" />
        </BarChart>

        {/* Line Chart */}
        <LineChart width={300} height={300} data={appointmentData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="admitted" stroke="#8884d8" />
          <Line type="monotone" dataKey="todaysAppointments" stroke="#82ca9d" />
        </LineChart>

        {/* Pie Chart */}
        <PieChart width={300} height={300}>
          <Pie
            data={appointmentData}
            dataKey="todaysAppointments"
            nameKey="category"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            label
          >
            {appointmentData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      <Outlet />
    </div>
  );
};

const styles = {
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f2f5',
    height: '100%',
    overflowX: 'hidden',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: '#ffffff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    flex: '1',
    margin: '0 10px',
    minWidth: '200px',
  },
  cardIcon: {
    fontSize: '2rem',
    color: '#007bff',
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#333',
  },
  cardValue: {
    fontSize: '1.5rem',
    color: '#007bff',
  },
  header: {
    textAlign: 'center',
    color: '#333',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  tableContainer: {
    width: '90%',
    margin: '20px 0',
  },
  responsiveTableWrapper: {
    overflowX: 'auto', // Allows horizontal scrolling on smaller screens
    WebkitOverflowScrolling: 'touch',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'center',
    fontSize: '0.9rem',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  tableCell: {
    padding: '12px',
    border: '1px solid #ddd',
  },
  tableHeader: {
    padding: '12px',
    border: '1px solid #ddd',
    backgroundColor: '#007bff',
    color: '#fff',
    fontWeight: 'bold',
  },
  evenRow: {
    backgroundColor: '#f8f9fa',
  },
  oddRow: {
    backgroundColor: '#ffffff',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    marginTop: '20px',
    flexWrap: 'wrap',
  },
};

export default Appointments;
