import React, { useState } from 'react';
import { Link } from "react-router-dom";

const DashboardNavbar = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <style>
        {`
          @font-face {
            font-family: 'robotoregular';
            src: url(../fonts/roboto-regular-webfont.woff) format('woff');
            font-weight: normal;
            font-style: normal;
          }

          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: "Poppins", "robotoregular", sans-serif;
          }

          :root {
            --bg-primary: #41444b;
            --bg-white: #ffffff;
            --bg-active: #fddb3a;
            --text-black: #000000;
            --icon-black: #000000;
          }

          body {
            min-height: 100vh;
            background: var(--bg-primary);
          }

          nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 60px;
            height: 100%;
            background: var(--bg-white);
            transition: width 0.3s, left 0.3s;
            overflow: hidden;
            z-index: 2;
          }

          nav.active {
            width: 300px;
          }

          nav ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }

          nav ul li {
            list-style: none;
            width: 100%;
          }

          nav ul li a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--text-black);
            padding: 15px;
            transition: color 0.2s;
          }

          nav ul li a:hover {
            color: var(--bg-primary);
          }

          nav ul li a .icon {
            min-width: 60px;
            text-align: center;
            color: var(--icon-black);
          }

          header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            background: var(--bg-white);
            padding: 0 15px;
            color: var(--text-black);
            position: sticky;
            top: 0;
            z-index: 1;
          }

          header h3 {
            font-size: 1.2em;
            color: var(--text-black);
          }

          header .toggle {
            cursor: pointer;
            display: none;
          }

          @media (max-width: 768px) {
            header .toggle {
              display: block;
            }

            nav {
              width: 0;
              left: -60px;
            }

            nav.active {
              left: 0;
              width: 60%;
              max-width: 300px;
            }

            nav ul li a .title {
              display: inline-block;
            }
          }
        `}
      </style>

      <header>
        <div className="toggle" onClick={toggleSidebar}>
          <i className="fas fa-bars" style={{ color: "var(--icon-black)" }}></i>
        </div>
      
        <a href="#">
          <i className="fas fa-sign-out-alt" style={{ color: "var(--icon-black)" }}></i>
        </a>
      </header>

      <nav className={isActive ? 'active' : ''}>
        <ul>
          <li>
            <a className="toggle" onClick={toggleSidebar}>
              <span className="icon">
                <i className="fas fa-bars" style={{ color: "var(--icon-black)" }}></i>
              </span>
              <span className="title">Menu</span>
            </a>
          </li>
          <li>
      <Link to="/dashboard">
        <span className="icon">
          <i className="fas fa-home" style={{ color: "var(--icon-black)" }}></i>
        </span>
        <span className="title">Home</span>
      </Link>
    </li>
    <li>
        <Link to="/pharmacy">
          <span className="icon">
            <i className="fas fa-capsules" style={{ color: "var(--icon-black)" }}></i>
          </span>
          <span className="title">Pharmacy</span>
        </Link>
      </li>
      <li>
        <Link to="/appointments">
          <span className="icon">
            <i className="fas fa-calendar-check" style={{ color: "var(--icon-black)" }}></i>
          </span>
          <span className="title">Appointments</span>
        </Link>
      </li>
          <li>
            <a href="#">
              <span className="icon">
                <i className="fas fa-info" style={{ color: "var(--icon-black)" }}></i>
              </span>
              <span className="title">Help</span>
            </a>
          </li>
          <li>
            <a href="#">
              <span className="icon">
                <i className="fas fa-cog" style={{ color: "var(--icon-black)" }}></i>
              </span>
              <span className="title">Setting</span>
            </a>
          </li>
          <li>
            <a href="#">
              <span className="icon">
                <i className="fas fa-lock" style={{ color: "var(--icon-black)" }}></i>
              </span>
              <span className="title">Password</span>
            </a>
          </li>
          <li>
            <a href="/">
              <span className="icon">
                <i className="fas fa-sign-out-alt" style={{ color: "var(--icon-black)" }}></i>
              </span>
              <span className="title">Sign Out</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default DashboardNavbar;
