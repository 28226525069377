import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Signup2 = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [age, setAge] = useState('');
    const [address, setAddress] = useState('');
    const [logo, setLogo] = useState(null);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validation and form submission code (as in your original code)...
    };

    return (
        <div style={styles.page}>
            <div style={styles.container}>
                <div style={styles.imageSection}>
                    <img
                        src="https://cdn-icons-png.flaticon.com/512/4228/4228704.png"
                        alt="Hospital"
                        style={styles.image}
                    />
                </div>
                <div style={styles.formSection}>
                    {errors.general && <p style={styles.error}>{errors.general}</p>}
                    <div style={styles.card}>
                        <h2 style={styles.heading}>Patient Signup</h2>
                        <form onSubmit={handleSubmit} style={styles.form}>
                        <div style={styles.row}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>First Name:</label>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.firstName && <p style={styles.error}>{errors.firstName}</p>}
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Last Name:</label>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.lastName && <p style={styles.error}>{errors.lastName}</p>}
                            </div>
                        </div>
                        <div style={styles.row}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Username:</label>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.username && <p style={styles.error}>{errors.username}</p>}
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email:</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.email && <p style={styles.error}>{errors.email}</p>}
                            </div>
                        </div>
                        <div style={styles.row}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Mobile:</label>
                                <input
                                    type="text"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.mobile && <p style={styles.error}>{errors.mobile}</p>}
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Age:</label>
                                <input
                                    type="text"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.age && <p style={styles.error}>{errors.age}</p>}
                            </div>
                        </div>
                        <div style={styles.row}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Address:</label>
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    style={styles.input}
                                />
                                {errors.address && <p style={styles.error}>{errors.address}</p>}
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Logo:</label>
                                <input
                                    type="file"
                                    onChange={(e) => setLogo(e.target.files[0])}
                                    style={styles.input}
                                />
                            </div>
                        </div>
                        <button type="submit" style={{ ...styles.button, backgroundColor: 'black', borderRadius: '20px', padding: '10px 20px' }}>
  Signup
</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    page: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '140vh',
        //backgroundColor: 'rgb(212, 240, 212)',
         // backgroundColor: 'rgb(178, 185, 247)',
         backgroundImage: 'url(https://media.istockphoto.com/id/1459714232/photo/defocused-empty-corridor-in-a-hospital.jpg?s=612x612&w=0&k=20&c=Rw298sqGwA6hcbKOkyrUVfu1HGoE1k4rIZZVrQxjUNs=)',
         backgroundSize: 'cover',
         backgroundPosition: 'center',
         backgroundRepeat: 'no-repeat',
        padding: '2rem',
    },
    container: {
        display: 'flex',
        maxWidth: '1200px',
        width: '100%',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    },
    imageSection: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    formSection: {
        flex: 1,
        padding: '2rem',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    heading: {
        fontSize: '2.5rem',
        margin: '0 0 1.5rem',
        color: '#333',
        textAlign: 'center',
    },
    card: {
        width: '100%',
        maxWidth: '500px',
        margin: '0 auto',
    },
    form: {
        display: 'block',
        textAlign: 'left',
        width: '100%',
    },
    input: {
        width: '100%',
        padding: '0.75rem',
        fontSize: '1rem',
        borderRadius: '6px',
        border: '1px solid #ddd',
        marginBottom: '1rem',
    },
    button: {
        padding: '0.75rem 1.5rem',
        fontSize: '1.1rem',
        cursor: 'pointer',
        //backgroundColor: '#007BFF',
        color: '#FFF',
        border: 'none',
        borderRadius: '6px',
        width: '100%',
        marginTop: '1.5rem',
        transition: 'background-color 0.3s',
    },
    error: {
        color: 'red',
        marginBottom: '0.5rem',
        fontWeight: 'bold',
    },
};

export default Signup2;
