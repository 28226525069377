import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Home = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [message, setMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [feedbackEmail, setFeedbackEmail] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const images = [
    'https://docpulse.com/wp-content/uploads/2024/02/Apolo-Ayurvaid.jpg',
    'https://img.freepik.com/free-vector/health-care-logo-icon_125964-471.jpg?semt=ais_hybrid',
    'https://www.logopeople.in/wp-content/uploads/2023/07/Inspiring-Hospital-Logo-Design-4.jpg',
    'https://www.logopeople.in/wp-content/uploads/2023/07/Elegance-1-1.jpg',
    'https://img.freepik.com/free-vector/hospital-logo-template_23-2150529366.jpg?w=360',
  ];

  useEffect(() => {
    axios.get('http://localhost:8000/api/productview/')
      .then(response => {
        setProducts(response.data);
        setFilteredProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }, []);
  const handleFeedbackSubmit = (event) => {
    event.preventDefault();
    // Here, you can handle the feedback submission to your backend
    console.log('Feedback submitted:', { feedbackEmail, feedbackMessage });
    // Clear the form
    setFeedbackEmail('');
    setFeedbackMessage('');
    alert('Feedback submitted successfully!');
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  const getCSRFToken = () => {
    let csrfToken = null;
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === 'csrftoken') {
        csrfToken = value;
        break;
      }
    }
    return csrfToken;
  };

  const handleAddToCart = (productId) => {
    if (!isAuthenticated()) {
      navigate('/login');
      return;
    }

    const csrfToken = getCSRFToken();
    axios.post(`http://localhost:8000/api/add_to_cart/${productId}/`, {}, {
      headers: {
        'X-CSRFToken': csrfToken,
      }
    })
      .then(response => {
        setMessage(response.data.message);
        setTimeout(() => {
          setMessage(null);
        }, 3000);
        navigate('/cart');
      })
      .catch(error => {
        console.error('Error adding to cart: ', error);
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterProducts(event.target.value);
  };

  const filterProducts = (term) => {
    if (!term) {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    return !!token;
  };

  return (
    <div style={styles.pageContainer}>
        <div style={styles.topImageContainer}>
        <img 
          src="https://img.freepik.com/free-photo/business-people-teamwork_23-2148827006.jpg?t=st=1730980920~exp=1730984520~hmac=6dd80f69e83147d8caea7d0018a1dcec652f882d1d445b23aad80efa5f7d953e&w=1380" 
          alt="Top Banner" 
          style={styles.topImage}
        />
      
      </div>
      <br />
      
     
      <br />
      <div style={styles.container}>
  <div style={styles.textContainer}>
    <div style={{ 
      ...styles.text, 
      background: 'linear-gradient(135deg, #6dd5ed, #2193b0)', 
      color: '#fff', 
      padding: '10px 20px', 
      borderRadius: '8px', 
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.3rem'
    }}>
      Explore Comprehensive Cloud-Based Healthcare Software Solutions Tailored for Hospitals, Clinics, and Doctors.
    </div>
    <p style={{
      color: '#333',
      fontSize: '1.1rem',
      lineHeight: '1.6',
      fontFamily: 'Arial, sans-serif',
      textAlign: 'justify',
      padding: '20px',
      borderRadius: '12px',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
      margin: '25px 0',
    
      transition: 'all 0.3s ease-in-out',
      maxWidth: '700px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}>
      <em>
        Experience state-of-the-art digital solutions with 
        <strong style={{ color: '#007BFF' }}> DocPulse’s CMS</strong> 
        (Clinic Management Software), 
        <strong style={{ color: '#28a745' }}> PMS</strong> 
        (Patient Management Software), and 
        <strong style={{ color: '#dc3545' }}> HIMS</strong> 
        (Hospital Integrated Management Software), designed to meet the unique needs of doctors, clinics, and hospitals.
        Adopt our digital healthcare ecosystem for a holistic approach towards patient management. 
        Our patient management system offers a wide range of features, including managing multi-chain facilities 
        centrally, appointment scheduling portals for patients, feedback collection channels, maintaining Electronic 
        Health Records (EHR), tracking pharmacy operations and stocks, and 
        <strong style={{ color: '#17a2b8' }}> LIS</strong> 
        for in-house pathology and radiology diagnostics.
      </em>
      Fully integrated modular software architecture with seamless data flow between departments for effortless patient data management.
    </p>
  </div>
</div>

      <br />
      <div style={styles.cardContainer}>
        {['https://st2.depositphotos.com/4126039/6265/i/450/depositphotos_62652099-stock-photo-mri-scanner-room.jpg', 
          'https://img.freepik.com/premium-photo/discover-future-healthcare-with-our-x-ray-machine_1264082-257477.jpg', 
          'https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsX29mZmljZV8yNV9zaW1wbGVfM2RfaWxsdXN0cmF0aW9uX29mX2FfcmVjb3Zlcnlfcm9vbV93aV80ZjhkNDIwNC02N2I4LTQwMDQtYTBlNy05YjljMjIyMzE2ZGVfMS5qcGc.jpg'].map((src, index) => (
          <div style={styles.card} key={index}>
            <img src={src} alt={`Card Image ${index + 1}`} style={styles.cardImage} />
            <p style={styles.cardDescription}>
              {index === 0 ? "Advanced diagnostic equipment designed for precise, reliable patient evaluations."
                : index === 1 ? "Modern hospital room with high-quality facilities for optimal patient care."
                : "State-of-the-art pharmacy management system ensuring efficient stock handling."}
            </p>
          </div>
        ))}
      </div>
       {/* Carousel showing 3 images at a time */}
       <div style={styles.imageCarousel}>
        <button onClick={handlePrevious} style={styles.arrowButton}>◀</button>
        <div style={styles.carouselContainer}>
          {images.slice(currentIndex, currentIndex + 3).map((src, index) => (
            <img key={index} src={src} alt={`Carousel ${index}`} style={styles.carouselImage} />
          ))}
        </div>
        <button onClick={handleNext} style={styles.arrowButton}>▶</button>
      </div>
      <br />
      <div style={styles.headingContainer}>
  <h1 style={styles.heading}>Hospital - Hospital Management System</h1>
  <p style={{
      ...styles.description,
      lineHeight: '1.6',
      fontSize: '1.1em',
      color: '#4a4a4a',
      padding: '10px 20px',
      borderLeft: '4px solid #007bff',
     
      borderRadius: '8px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    }}>
    <em>
      Hospital Systems is a comprehensive and integrated Hospital Management System tailored to meet the needs of hospital systems, medical centers, multi-specialty clinics, and medical practitioners. This all-encompassing system connects hospitals, satellite clinics, and medical stores through its Multi-Location functionality. Unlike traditional paper-based methods, it leverages technology to pull up server or cloud information swiftly, ensuring top performance.
      <br /><br />
      Our user-friendly EHR Software eliminates handwriting errors and allows easy access to revenue streams, patient records, and essential real-time metrics. The software also facilitates the electronic sharing of patient records, empowering both patients and providers to view lab results, chat securely, and schedule appointments online.
      <br /><br />
      A customizable alert system sends reminders via text, IM, and email to enhance patient care. Booking appointments with doctors based on their specialty, rating, fees, and availability is simple. Plus, organizing doctor schedules, compiling patient notes, and handling payments become effortless tasks.
      <br /><br />
      With eHospital – Hospital Management System, doctors, and patients can check their calendars on mobile devices, contributing to a more organized life. The software is available for both on-premise and on-cloud installations, all built on best practices from around the globe.
    </em>
  </p>
</div>

      <br />
      <div style={styles.imageRowContainer}>
        {['https://images3.alphacoders.com/101/1010294.jpg', 
          'https://media.istockphoto.com/id/2015414448/photo/pharmaceutical-sales-representative-presenting-new-medication-to-doctors-in-medical-building.jpg?s=612x612&w=0&k=20&c=oJ6LASPPNYb2FHi_fTEqdivyoqQ_MfceC_cvZ4whM7k=', 
          'https://t3.ftcdn.net/jpg/07/95/19/34/360_F_795193432_IcSQendilOh3IdaD5bQXhJqGe8WCrOE2.jpg'].map((src, index) => (
          <div style={styles.imageBox} key={index}>
            <img src={src} alt={`Image ${index + 1}`} style={styles.image} />
          </div>
        ))}
      </div>
      <br/>
  <br/>
  <br/>

    
      <footer style={styles.footer}>
  <div style={styles.footerContainer}>
    <img src="https://i.postimg.cc/Nj9dgJ98/cards.png" alt="Payment Methods" style={styles.paymentMethods} />
  </div>

  <div style={styles.linksContainer}>
    <a href="/about" style={styles.link}>About Us</a>
    <a href="/contact" style={styles.link}>Contact</a>
    <a href="/privacy" style={styles.link}>Privacy Policy</a>
    <a href="/terms" style={styles.link}>Terms of Service</a>
  </div>

  <div style={styles.newsletterContainer}>
    <input
      type="email"
      placeholder="Subscribe to our newsletter"
      style={styles.newsletterInput}
    />
    <button style={styles.newsletterButton}>Subscribe</button>
  </div>

  <div style={styles.footerBottom}>
    <button style={styles.footerButton}>Back to Top</button>
    <button style={styles.footerButton}>Contact Support</button>
  </div>
</footer>


    </div>
  );
};

const styles = {
  
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    //backgroundColor: 'rgb(212, 240, 212)', // Light green background
   backgroundImage: 'url(https://img.freepik.com/premium-photo/solid-light-blue-background_728418-18496.jpg)', // Specify the path to your image
    backgroundSize: 'cover', // Cover the entire container
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
  },
  topImageContainer: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  topImage: {
    width: '100%',
    height: '500px',
    display: 'block',
  },
  overlayText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '10px',
    borderRadius: '8px',
  },
 
  headingContainer: {
    padding: '20px',
    textAlign: 'center',
    maxWidth: '1000px', // Increased maxWidth for the container
    margin: '0 auto',
  },
  heading: {
    fontSize: '28px',
    fontWeight: '700',
    color: 'black',
    marginBottom: '15px',
  },
  description: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#555',
    textAlign: 'justify',
    padding: '15px 20px',        // Additional padding for readability
   // backgroundColor: '#f7f7f7',
   //backgroundColor: 'rgb(212, 240, 212)', // Light green background
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    margin: '20px auto',
    maxWidth: '1000px',          // Increased maxWidth to 1000px for a wider box
  },



  
  
  imageCarousel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  carouselContainer: {
    display: 'flex',
    overflow: 'hidden',
    width: '75%',
    justifyContent: 'space-around',
    '@media (max-width: 768px)': {
      width: '90%',
    },
  },
  carouselImage: {
    width: '100px', // Adjusted for better responsiveness
    height: '100px',
    borderRadius: '8px',
    margin: '0 5px',
    transition: 'transform 0.3s ease',
    '@media (max-width: 768px)': {
      width: '80px',
      height: '80px',
    },
  },
  arrowButton: {
    fontSize: '24px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color: 'green',
    padding: '10px',
  },
  imageRowContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px',
  },
  imageBox: {
    flex: 1,
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '8px',
    transition: 'transform 0.3s ease',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    maxWidth: '1400px',
    margin: 'auto',
    flexDirection: 'row', // Default to row layout for larger screens
  },
  image: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    marginRight: '20px',
  },
  textContainer: {
    flex: 1,
  },
  text: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
  },
  paragraph: {
    fontSize: '16px',
    color: '#333',
    marginTop: '10px',
  },
  // Mobile styles
  '@media (max-width: 768px)': {
    container: {
      flexDirection: 'column', // Stack elements vertically on smaller screens
      alignItems: 'center',    // Center-align contents
      textAlign: 'center',     // Center-align text in container
    },
    image: {
      marginRight: '0', // Remove right margin on mobile
      marginBottom: '20px', // Add bottom margin for spacing
    },
    text: {
      fontSize: '18px',
    },
    paragraph: {
      fontSize: '14px',
    },
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    flexWrap: 'wrap', // Allow cards to wrap
  },
  card: {
    flex: '1 1 300px', // Allow card to grow and shrink with a base size of 300px
    textAlign: 'center',
    padding: '10px',
    backgroundColor: 'white', // Set card background color to white
    borderRadius: '8px', // Optional: Add border radius to cards
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Optional: Add a shadow for better visibility
    margin: '10px', // Margin between cards
    maxWidth: 'calc(33% - 20px)', // Max width for larger screens
    boxSizing: 'border-box', // Ensure padding and border are included in width/height
    transition: 'all 0.3s ease', // Added transition for smooth hover effects
    cursor: 'pointer' // Added cursor pointer for clickable cards
},

  cardImage: {
    width: '100%', // Full width for mobile responsiveness
    maxWidth: '300px', // Set a maximum width for card images
    height: 'auto',
    borderRadius: '8px',
  },
  cardDescription: {
    fontSize: '14px',
    marginTop: '10px',
  },
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  paymentMethods: {
    maxWidth: '200px',
    margin: '0 auto',
  },
  linksContainer: {
    marginBottom: '20px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    margin: '0 15px',
    fontSize: '14px',
  },
  newsletterContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  newsletterInput: {
    padding: '10px',
    fontSize: '14px',
    border: 'none',
    borderRadius: '5px',
    marginRight: '10px',
    width: '250px',
  },
  newsletterButton: {
    padding: '10px 15px',
    backgroundColor: '#ff6f61',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '14px',
    cursor: 'pointer',
  },
  footerBottom: {
    marginTop: '20px',
  },
  footerButton: {
    padding: '10px 15px',
    backgroundColor: '#444',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '14px',
    margin: '0 10px',
    cursor: 'pointer',
  },
};


export default Home;
