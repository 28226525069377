import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const Signup = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        email: '',
        phoneNumber: '',
        age: '',
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, username, password, email, phoneNumber, age } = formData;

        if (!firstName || !lastName || !username || !password || !email || !phoneNumber || !age) {
            setError("All fields are required.");
            return;
        }

        try {
            const csrfToken = Cookies.get('csrftoken');
            const response = await axios.post('http://localhost:8000/api/signup/', formData, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) {
                window.location.href = '/login';
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error || 'An error occurred.');
            } else if (error.request) {
                setError('No response received from server.');
            } else {
                setError('Error in setting up the request.');
            }
        }
    };

    return (
        <div style={styles.page}>
            <div style={styles.container}>
                <div style={styles.imageContainer}>
                    <img
                        src="https://img.freepik.com/free-vector/co-workers-illustration-concept_114360-818.jpg"
                        alt="Signup Illustration"
                        style={styles.image}
                    />
                </div>
                <div style={styles.formContainer}>
                    <h2 style={styles.heading}>Signup</h2>
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>First Name:</label>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Last Name:</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Username:</label>
                            <input
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Password:</label>
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Phone Number:</label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Age:</label>
                            <input
                                type="number"
                                name="age"
                                value={formData.age}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>
                        <button type="submit" style={styles.button}>Sign Up</button>
                    </form>
                    {error && <p style={styles.error}>{error}</p>}
                    <p style={styles.signupText}>
                        Already have an account? <a href="/login" style={styles.signupLink}>Login</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

const styles = {
    page: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '120vh',
        backgroundImage: 'url(https://img.freepik.com/premium-photo/solid-light-blue-background_728418-18496.jpg) ',
        backgroundColor: '#f8f9fa',
        padding: '1rem',
    },
    container: {
        display: 'flex',
        maxWidth: '900px',
        width: '100%',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '12px',
        overflow: 'hidden',
        backgroundColor: '#fff',
    },
    imageContainer: {
        flex: 1,
        backgroundColor: '#007bff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    formContainer: {
        flex: 1,
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    heading: {
        fontSize: '2rem',
        marginBottom: '1.5rem',
        color: '#333',
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '1rem',
    },
    label: {
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '0.5rem',
    },
    input: {
        padding: '0.75rem',
        fontSize: '1rem',
        borderRadius: '6px',
        border: '1px solid #ddd',
        width: '100%',
    },
    button: {
        padding: '0.75rem',
        fontSize: '1rem',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '1rem',
    },
    error: {
        color: 'red',
        marginTop: '1rem',
        textAlign: 'center',
    },
    signupText: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    signupLink: {
        color: '#007bff',
        textDecoration: 'none',
        fontWeight: 'bold',
    },
};

export default Signup;
